import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

const url = "https://jimz.cc";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
